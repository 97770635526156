
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow.js";
import ImageDisplayRow from "@/portals/shared/cell/image/ImageDisplayRow.js";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow.js";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import PasswordUpdateRow from "@/portals/shared/cell/password/PasswordUpdateRow.js";
import Credentials from '@/domain/model/user/Credentials.js';


import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow.js";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow.js";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow.js";
import MC from "@/domain/session/MC.js";
import WebLogger from "@/domain/logger/WebLogger.js";
import SignInModel from "./SignInModel.js";

export default class SignInModelUtils {

  static buildRowData(domain, panel, tableData) {
    var au = new SignInModelUtils(domain, panel, tableData);
    au.start();
  }

  constructor(panel, tableData) {
    this._panel = panel;
    this._tableData = tableData;
    this.MC = new MC();
    this._logger = new WebLogger(window.console, true);
  }

  panel() {
    return this._panel;
  }

  credentials() {
    var panel = this.panel();
    var model = panel.model();
    return model.credentials();
  }

  logger() {
    return this._logger;
  }

  start() {
    var model = this.panel().model();
    for (var spacers = 0; spacers < 6; spacers++) {
      this.addRow(new SpacerDisplayRow());
    }

    var signInOrWelcomeRow = new StringDisplayRow().withValue("Sign in").withAlignLeft().withStyle("rc-font-extra-large");
    var continueorEmailRow = new StringDisplayRow().withValue("To continue with ReadyChek").withAlignLeft();

    if (this.credentials().hasEmail()) {
      signInOrWelcomeRow.withValue("Welcome");
      continueorEmailRow.withValue(this.credentials().email());
    }
    var mainKids = [];
    var hint = model.error().hint;
    if (model.isStateLoading()) {
      mainKids = [
        new SpacerDisplayRow(),
        new SpacerDisplayRow(),
        new StringDisplayRow().withValue(model.status()).withStyle("rc-font-large"),
      ];
    } else {
      mainKids = [
        new StringUpdateRow().withName("Email").withMandatoryValue(true).withField(Credentials.FIELDS.Email).withDefaultValue(this.credentials().email()),
        new PasswordUpdateRow().withName("Password").withHint("Forgot your password?").withHintUrl("/recovery").withMandatoryValue(true).withField(Credentials.FIELDS.Password).withDefaultValue(this.credentials().password()),
        new RowDisplayRow()
          .withChildren([
            new BoxDisplayRow()
            .withWidth(15)
            .withChildren([
                new ButtonDisplayRow().withWidth(15).withName("Sign In").withBackgroundPrimaryAction().withField(SignInModel.Fields.SignInButton),
             ]),
          ]),
      ];
    }

    this.addRow(new RowDisplayRow()
      .withBorder(false)
      .withChildren([

        new BoxDisplayRow()
          .withWidth(3)
          .withChildren([]),
        new BoxDisplayRow()
          .withWidth(9)
          .withPrimaryBackgroundColour()
          .withBorder(true)
          .withBorderStyleLarge()
          .withChildren([
            new SpacerDisplayRow(),
            new RowDisplayRow()
              .withChildren([
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
                new BoxDisplayRow()
                  .withWidth(13)
                  .withChildren([
                    new StringDisplayRow().withValue(hint + " ")
                      .withStyleEven()
                      .withStyle("rc-title-error-left rc-font-regular-large"),
                  ]),
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
              ]),
            new RowDisplayRow()
              .withBorder(false)
              .withChildren([
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
                new BoxDisplayRow()
                  .withWidth(7)
                  .withChildren([
                    new ImageDisplayRow().withImageUrl("@/assets/2019/ReadyChekIcon.png"),
                    signInOrWelcomeRow,
                    continueorEmailRow,
                  ]),
                new BoxDisplayRow()
                  .withWidth(6)
                  .withChildren(
                    mainKids,
                  ),
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
              ]),
            new SpacerDisplayRow(),
          ]),
        new BoxDisplayRow()
          .withWidth(3)
          .withChildren([]),
      ]));
    this.addRow(new RowDisplayRow()
      .withBorder(false)
      .withChildren([

        new BoxDisplayRow()
          .withWidth(3)
          .withChildren([]),
        new BoxDisplayRow()
          .withWidth(9)
          .withPrimaryBackgroundColour()
          .withBorder(true)
          .withBorderStyleLarge()
          .withChildren([
            new SpacerDisplayRow(),
            new RowDisplayRow()
              .withChildren([
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
                new BoxDisplayRow()
                  .withWidth(7)
                  .withChildren([
                    new StringDisplayRow().withValue("New? If you don't have an account yet click the Create Account button")
                  ]),
                new BoxDisplayRow()
                  .withWidth(6)
                  .withChildren([
                    new ButtonDisplayRow().withWidth(15).withName("Create Account").withBackgroundNoAction().withField(SignInModel.Fields.RegistrationButton),
                  ]),
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
                ]),
            new SpacerDisplayRow(),
          ]),
          new BoxDisplayRow()
            .withWidth(3)
            .withChildren([]),
        ]));
  }

  addRow(row) {
    this._tableData.push(row);
  }
}
/*
<!--
this.addRow(
  new RowDisplayRow()
  .withBorder(false)
  .withChildren([

    new BoxDisplayRow()
      .withWidth(3)
      .withChildren([]),
    new BoxDisplayRow()
      .withWidth(9)
      .withPrimaryBackgroundColour()
      .withBorder(true)
      .withBorderStyleLarge()
      .withChildren([
        new ButtonDisplayRow().withWidth(15).withName("Create Account").withBackgroundNoAction().withField(SignInModel.Fields.RegistrationButton),
                     ]),
    new BoxDisplayRow()
      .withWidth(3)
      .withChildren([
      ]),
    ])); --!>
    */